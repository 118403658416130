define('rating-frontend-demo/initializers/ajax-setup', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
    _ember['default'].$.ajaxSetup({
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    });
  }

  exports['default'] = {
    name: 'ajax-setup',
    initialize: initialize
  };
});