define('rating-frontend-demo/components/coverage/coverage-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    actions: {
      editCoverage: function editCoverage(coverage) {
        this.sendAction('showCoverageForm', coverage);
      },
      deleteCoverage: function deleteCoverage(coverage) {
        this.sendAction('deleteCoverage', coverage);
      }
    }
  });
});