define('rating-frontend-demo/router', ['exports', 'ember', 'rating-frontend-demo/config/environment'], function (exports, _ember, _ratingFrontendDemoConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _ratingFrontendDemoConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('index', { 'path': '/' }, function () {});
    this.route('quote', function () {
      this.route('quote-info', { path: '/:quote_id/quote-info' });
      this.route('insureds', { path: '/:quote_id/insureds' });
      this.route('locations', { path: '/:quote_id/locations' });
      this.route('coverage', { path: '/:quote_id/coverage' });
      this.route('rate-detail', { path: '/:quote_id/rate-detail' });
      this.route('new');
    });
  });

  exports['default'] = Router;
});