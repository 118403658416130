define('rating-frontend-demo/models/location', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _emberDataModel, _emberDataAttr, _emberData) {
  exports['default'] = _emberDataModel['default'].extend({
    quote: _emberData['default'].belongsTo('quote'),
    number: (0, _emberDataAttr['default'])('number'),
    address: (0, _emberDataAttr['default'])('string'),
    city: (0, _emberDataAttr['default'])('string'),
    state: (0, _emberDataAttr['default'])('string'),
    ZIP: (0, _emberDataAttr['default'])('string'),
    geo: (0, _emberDataAttr['default'])('string'),
    protection_class: (0, _emberDataAttr['default'])('string')
  });
});