define('rating-frontend-demo/components/rating/display-step', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showSteps: false,
    isLiability: _ember['default'].computed('step.identifier', function () {
      var step = this.get('step');
      if (step && step['identifier'] === "rate:liability") {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      toggleSteps: function toggleSteps() {
        this.toggleProperty('showSteps');
      }
    }
  });
});