define('rating-frontend-demo/components/locations/location-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    rating: _ember['default'].inject.service('rating'),
    store: _ember['default'].inject.service(),
    locationFormModel: null,

    actions: {
      addLocation: function addLocation() {
        // I might be overreaching by going directly to the datastore here in the component
        // instead of the route.. but I'm having trouble accessing the location after it is saved
        // if I don't.
        var model = this.get('model'); // this model is the quote
        var data = {
          quote: model
        };
        var location = this.get('store').createRecord('location', data);
        model.save(); // both sides need to be saved at first
        this.showLocationForm(location);
      },
      deleteLocation: function deleteLocation(location) {
        var _this = this;
        var quote = this.get('model');
        location.destroyRecord();
        quote.save().then(function () {
          console.log("rating because location deleted");
          _this.get("rating").ratePolicy(quote);
        });
      },
      updateLocation: function updateLocation(location) {
        var _this = this;
        var quote = this.get('model');

        location.save().then(function () {
          console.log("rating because location change");
          _this.get("rating").ratePolicy(quote);
        });
      },
      showLocationForm: function showLocationForm(location) {
        this.showLocationForm(location);
      },
      closeDialog: function closeDialog() {
        document.querySelector('#location-dialog').close();
      }
    },
    showLocationForm: function showLocationForm(coverage) {
      this.set('locationFormModel', coverage);
      document.querySelector('#location-dialog').showModal();
    }
  });
});