define('rating-frontend-demo/components/coverage/coverage-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    rating: _ember['default'].inject.service('rating'),
    store: _ember['default'].inject.service(),
    coverageFormModel: null,

    actions: {
      addCoverage: function addCoverage() {
        // I might be overreaching by going directly to the datastore here in the component
        // instead of the route.. but I'm having trouble accessing the coverage after it is saved
        // if I don't.
        var model = this.get('model'); // this model is the quote
        var data = {
          quote: model
        };
        var coverage = this.get('store').createRecord('coverage', data);
        model.save(); // both sides need to be saved at first
        this.showCoverageForm(coverage);
      },
      deleteCoverage: function deleteCoverage(coverage) {
        var _this = this;
        var quote = this.get('model');
        coverage.destroyRecord();
        quote.save().then(function () {
          console.log("rating because coverage deleted");
          _this.get("rating").ratePolicy(quote);
        });
      },

      updateCoverage: function updateCoverage(coverage) {
        var _this = this;
        var quote = this.get('model');
        coverage.save().then(function () {
          console.log("rating because coverage changed");
          _this.get("rating").ratePolicy(quote);
        });
      },
      showCoverageForm: function showCoverageForm(coverage) {
        this.showCoverageForm(coverage);
      },
      closeDialog: function closeDialog() {
        document.querySelector('#coverage-dialog').close();
      }
    },

    showCoverageForm: function showCoverageForm(coverage) {
      this.set('coverageFormModel', coverage);
      document.querySelector('#coverage-dialog').showModal();
    }
  });
});