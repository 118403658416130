define('rating-frontend-demo/routes/quote/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    beforeModel: function beforeModel() {
      var d = new Date();
      var record = this.store.createRecord('quote', {
        effective_date: d.toJSON().slice(0, 10)
      });
      record.save();
      this.transitionTo("quote.quote-info", record);
    }
  });
});