define('rating-frontend-demo/components/insureds/insured-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      editInsured: function editInsured(insured) {
        this.sendAction('showInsuredForm', insured);
      },
      deleteInsured: function deleteInsured(insured) {
        this.sendAction('deleteInsured', insured);
      }
    }
  });
});