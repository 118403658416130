define('rating-frontend-demo/models/quote', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _emberDataModel, _emberDataAttr, _emberData) {
  exports['default'] = _emberDataModel['default'].extend({
    quote_name: (0, _emberDataAttr['default'])('string'),
    status: (0, _emberDataAttr['default'])('string', {
      defaultValue: function defaultValue() {
        return 'active';
      }
    }),
    deductible: (0, _emberDataAttr['default'])('string'),
    effective_date: (0, _emberDataAttr['default'])('string'),
    policy_type: (0, _emberDataAttr['default'])('string'),
    insureds: _emberData['default'].hasMany('insured', { async: true }),
    coverage: _emberData['default'].hasMany('coverage', { async: true }),
    locations: _emberData['default'].hasMany('location', { async: true }),
    home_auto_discount: (0, _emberDataAttr['default'])('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    // rate result stuff
    rate_result: (0, _emberDataAttr['default'])('string'),
    premium: (0, _emberDataAttr['default'])('string'),
    needs_rated: (0, _emberDataAttr['default'])('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    created: (0, _emberDataAttr['default'])('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updated: (0, _emberDataAttr['default'])('date')
  });
});