define('rating-frontend-demo/components/quote--quote-info', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    change: function change() {
      this.sendAction('updateQuote', this.get('quote'));
      return false;
    },
    actions: {
      updateQuote: function updateQuote() {
        var quote = this.get('quote');
        this.sendAction('updateQuote', quote);
      }
    }
  });
});