define('rating-frontend-demo/components/locations/location-list-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      editLocation: function editLocation(location) {
        this.sendAction('showLocationForm', location);
      },
      deleteLocation: function deleteLocation(location) {
        this.sendAction('deleteLocation', location);
      }
    }
  });
});