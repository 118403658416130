define('rating-frontend-demo/models/coverage', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data'], function (exports, _emberDataModel, _emberDataAttr, _emberData) {

  // attr types are string number boolean and date
  exports['default'] = _emberDataModel['default'].extend({
    quote: _emberData['default'].belongsTo('quote'),
    rate_code: (0, _emberDataAttr['default'])('string'),
    amount: (0, _emberDataAttr['default'])('number'),
    construction_type: (0, _emberDataAttr['default'])('string'), // brick / frame
    coverage_form: (0, _emberDataAttr['default'])('string'), // basic / broad / special

    year_built: (0, _emberDataAttr['default'])('number'),
    location: (0, _emberDataAttr['default'])('string'), // the id of a location item. Can be null
    protective_device: (0, _emberDataAttr['default'])('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),

    order: (0, _emberDataAttr['default'])('number'),
    notes: (0, _emberDataAttr['default'])('string'),
    created: (0, _emberDataAttr['default'])('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updated: (0, _emberDataAttr['default'])('date')
  });
});