define('rating-frontend-demo/components/locations/location-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      closeDialog: function closeDialog() {
        this.sendAction('closeDialog');
      },
      updateQuote: function updateQuote() {
        var location = this.get('location');
        this.sendAction('updateLocation', location);
      }
    }
  });
});