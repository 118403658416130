define('rating-frontend-demo/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.query('quote', {
        orderBy: 'created',
        limitToLast: 5
      });
    },
    actions: {
      startNewQuote: function startNewQuote() {
        this.transitionTo("quote.new");
      }
    }
  });
});