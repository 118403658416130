define('rating-frontend-demo/components/insureds/insured-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      closeDialog: function closeDialog() {
        this.sendAction('closeDialog');
      },
      updateQuote: function updateQuote() {
        var insured = this.get('insured');
        this.sendAction('updateInsured', insured);
      }
    }
  });
});