define('rating-frontend-demo/services/rating', ['exports', 'ember', 'rating-frontend-demo/config/environment'], function (exports, _ember, _ratingFrontendDemoConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    getPremium: function getPremium(rateset_id, quote_data) {
      var company_id = '1';
      var url = _ratingFrontendDemoConfigEnvironment['default'].APPS_CONFIG.ratingApiDomain + '/' + company_id + '/' + rateset_id + '/premiums/';
      return _ember['default'].$.ajax({
        type: "POST",
        url: url,
        data: quote_data,
        contentType: "application/json",
        dataType: 'json'
      });
    },
    getRateSetId: function getRateSetId(effective_date, state, lob) {
      //todo: actually do this.
    },
    ratePolicy: function ratePolicy(quote) {
      // quote is the quote model containing everything
      // we'll prepare the data, send the request, then update the model with the results
      // do we need to set the "needs_rating" elsewhere and only actually do anything here if we need rated?
      var _this = this;
      quote.set("needs_rating", true);
      quote.save();

      var all_coverage = quote.get("coverage");
      var locations = quote.get("locations");
      var insureds = quote.get("insureds");
      _ember['default'].RSVP.all([all_coverage, locations, insureds]).then(function () {
        // wait until we have all the related data to try and get the quote
        var rateset_id = '5d799f8f926d4642866c9d6a';
        var property_coverage = [];
        all_coverage.forEach(function (coverage) {
          var location_detail = _this.getLocationDetail(coverage.get('location'), locations);

          if (coverage.get('rate_code')) {
            property_coverage.push({
              "coverage_code": coverage.get('rate_code'),
              "coverage_amount": coverage.get('amount'),
              "protection_class": location_detail ? location_detail.get('protection_class') : "",
              "construction_type": coverage.get('construction_type'),
              "coverage_form": coverage.get('coverage_form'),
              "year_built": coverage.get('year_built'),
              "deductible": quote.get('deductible'),
              "zip_code": location_detail ? location_detail.get('ZIP') : "",
              "protective_device": coverage.get('protective_device') === true ? "yes" : "no",
              "home_auto_discount": quote.get('home_auto_discount') === true ? "yes" : "no"
            });
          }
        });
        var quote_data = {
          "property": property_coverage,
          "effective_date": quote.get("effective_date")
        };
        console.log("Quote Data to rate");
        console.log(quote_data);
        _this.getPremium(rateset_id, JSON.stringify(quote_data)).then(function (data) {
          var result = JSON.parse(data['result']);
          var premium = result['premium'];

          quote.set('premium', premium);
          quote.set('rate_result', JSON.stringify(result));
          quote.set("needs_rating", false);
          quote.save();
        });
      });
    },
    getLocationDetail: function getLocationDetail(coverage_location, locations) {
      // coverage location is the id of the location, locations is the list of locations
      var location_detail;
      locations.forEach(function (location) {
        if (location.get("id") === coverage_location) {
          location_detail = location;
        }
      });
      return location_detail;
    }
  });
});