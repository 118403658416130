define('rating-frontend-demo/components/coverage/coverage-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isDwelling: _ember['default'].computed('coverage.rate_code', function () {
      var coverage = this.get('coverage');
      if (coverage && coverage.get('rate_code') === 'HO') {
        return true;
      } else {
        return false;
      }
    }),
    change: function change() {
      this.sendAction('updateCoverage', this.get('coverage'));
      return false;
    },
    actions: {
      closeDialog: function closeDialog() {
        this.sendAction('closeDialog');
      },
      updateQuote: function updateQuote() {
        var coverage = this.get('coverage');
        this.sendAction('updateCoverage', coverage);
      }
    }
  });
});