define('rating-frontend-demo/routes/quote/quote-info', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    rating: _ember['default'].inject.service('rating'),

    model: function model(params) {
      return this.store.find('quote', params.quote_id);
    },
    actions: {
      updateQuote: function updateQuote(model) {
        model.save();
        console.log("rating because quote summary changed");
        this.get("rating").ratePolicy(model);
      }
    }
  });
});