define("rating-frontend-demo/templates/components/insureds/insured-list-item", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "rating-frontend-demo/templates/components/insureds/insured-list-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("li");
        dom.setAttribute(el1, "class", "mdl-list__item quote-summary-item");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "material-icons mdl-list__item-icon");
        var el3 = dom.createTextNode("person");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "mdl-layout-spacer");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "mdl-list__item-secondary-action");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "material-icons");
        var el4 = dom.createTextNode("edit");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "mdl-list__item-secondary-action");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "material-icons");
        var el4 = dom.createTextNode("delete");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [9]);
        var element2 = dom.childAt(element0, [11]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createElementMorph(element2);
        return morphs;
      },
      statements: [["content", "insured.first_name", ["loc", [null, [3, 4], [3, 26]]]], ["content", "insured.last_name", ["loc", [null, [3, 32], [3, 53]]]], ["element", "action", ["editInsured", ["get", "insured", ["loc", [null, [5, 72], [5, 79]]]]], [], ["loc", [null, [5, 49], [5, 81]]]], ["element", "action", ["deleteInsured", ["get", "insured", ["loc", [null, [6, 74], [6, 81]]]]], [], ["loc", [null, [6, 49], [6, 83]]]]],
      locals: [],
      templates: []
    };
  })());
});