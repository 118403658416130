define('rating-frontend-demo/components/rating/premium-steps', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    result: _ember['default'].computed('quote.rate_result', function () {
      return JSON.parse(this.get('quote').get('rate_result'));
    }),

    is_success: _ember['default'].computed('result', function () {
      return this.get('result')['status'] === 'success';
    })
  });
});